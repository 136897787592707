import React, { useEffect, useRef, useState } from 'react'
import { Button, Input } from 'antd'
import Icon from '../Icons/Icons'
import './searchResultItem.less'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { StorageKnowledgeRecord } from '../../../types/storage.type'
import { useAppSelector } from '../../../hooks/appHook'
import { RolesEnum } from '../../../config/rolesEnum'

interface Props {
  item: StorageKnowledgeRecord
  handleDeleteItem: (item: StorageKnowledgeRecord) => void
  handleEditData: (value: string, id: string) => void
}



const formatMarkdownLinks = (text: string) => {
  return (
    <ReactMarkdown
      components={{
        a: ({ href, children }) => (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        ),
      }}
    >
      {text}
    </ReactMarkdown>
  )
}

const SearchResultItem = ({ item, handleDeleteItem, handleEditData }: Props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [value, setValue] = useState(item.text)
  const { user } = useAppSelector((state) => state.profile)

  const formattedText = formatMarkdownLinks(value)

  useEffect(() => {
    setValue(item.text)
  }, [item])

  const handleSetItemEditable = () => {
    setIsDisabled(false)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleCancelEditing = () => {
    setValue(item.text)
    setIsDisabled(true)
  }

  const onEdit = () => {
    handleEditData(value, item.id)
    setIsDisabled(true)
  }

  return (
    <div className="ai-search-data-container">
      {isDisabled ? (
        <>
          <pre className="ai-data-paragraph">{formattedText}</pre>
          {user?.role === RolesEnum.ADMIN && (
            <div>
              <div>
                <b>Distance: </b>
                <span className="ai-data-paragraph-text">{item.distance}</span>
              </div>
              <div>
                <b>ID: </b>
                <span className="ai-data-paragraph-text">{item.id}</span>
              </div>
              <div>
                <b>Metadata: </b>
                <span className="ai-data-paragraph-text">{item.metadata ? JSON.stringify(item.metadata) : ''}</span>
              </div>
            </div>
          )}
        </>
      ) : (
        <Input.TextArea
          autoSize
          ref={inputRef}
          disabled={isDisabled}
          value={value}
          className="ai-data-input"
          onChange={(e) => setValue(e.target.value)}
        />
      )}
      <div className="buttons-container">
        <Button
          className="custom-text-button"
          icon={<Icon name="pencil" />}
          type="link"
          onClick={handleSetItemEditable}
          disabled={!isDisabled}
        >
          <span className="button-text">Edit</span>
        </Button>
        <Button
          onClick={() => handleDeleteItem(item)}
          className="custom-text-button"
          icon={<Icon name="trash" />}
          type="link"
        >
          <span className="button-text">Delete</span>
        </Button>
        {!isDisabled && (
          <>
            <Button className="custom-text-button" icon={<Icon name="synchronization" />} type="link" onClick={onEdit}>
              <span className="button-text">Update</span>
            </Button>
            <Button
              onClick={handleCancelEditing}
              className="custom-text-button"
              icon={<Icon name="cross" />}
              type="link"
            >
              <span className="button-text">Cancel</span>
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default SearchResultItem
