import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Input } from 'antd'
import AppPrimaryButton from '../../../../../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useErrorMessage } from '../../../../../../../../hooks/useErrorMessage'
import { useSuccessMessage } from '../../../../../../../../hooks/useSuccessMessage'
import { useWarningMessage } from '../../../../../../../../hooks/useWarningMessage'
import { useAppSelector } from '../../../../../../../../hooks/appHook'
import {requiredValidator} from "../../../../../../../../helpers/validators";
import {
  useCreateStorageKnowledgeRecordByStorageIdMutation,
  useCreateStorageKnowledgeRecordByUrlMutation, useDeleteStorageKnowledgeRecordByIdMutation,
} from '../../../../../../../../store/api/storage.api'
import {
  setAddedKnowledgeRecords,
  setEditedKnowledgeRecordId,
  setEditedKnowledgeRecords,
  setKnowledgeRecords,
  setIsSuccess
} from '../../../../../../../../store/slices/storage.slice'
import { StorageKnowledgeRecord } from '../../../../../../../../types/storage.type'
import KnowledgeRecordsList from '../../../../../KnowledgeRecordsList/KnowledgeRecordsList'

const AddByUrlTab = () => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  /** Storage */
  const {
    storageKnowledgeRecords,
    editedKnowledgeRecordId,
    addedKnowledgeRecords,
    currentStorageId
  } = useAppSelector((state) => state.storage)

  /** Storage Actions */
  const [
    createStorageKnowledgeRecordByUrl,
    createStorageKnowledgeRecordByUrlMutationResult
  ] = useCreateStorageKnowledgeRecordByUrlMutation()

  const [
    createStorageKnowledgeRecord,
    createStorageKnowledgeRecordMutationResult
  ] = useCreateStorageKnowledgeRecordByStorageIdMutation()

  const [
    deleteStorageKnowledgeRecord,
    deleteAiDataMutationResult
  ] = useDeleteStorageKnowledgeRecordByIdMutation()

  /** Response message handlers */
  useErrorMessage(
    'Something went wrong',
    createStorageKnowledgeRecordByUrlMutationResult.error ||
    createStorageKnowledgeRecordMutationResult.error ||
    deleteAiDataMutationResult.error,
  )
  useSuccessMessage(
    'You data was successfully added. Please check below and adjust if needed.',
    createStorageKnowledgeRecordByUrlMutationResult.isSuccess &&
    !!createStorageKnowledgeRecordByUrlMutationResult.data?.documents?.length,
  )
  useWarningMessage(
    'Sorry, we could not index provided URL as it is protected from any indexing.',
    createStorageKnowledgeRecordByUrlMutationResult.isSuccess &&
    !createStorageKnowledgeRecordByUrlMutationResult.data?.documents?.length,
  )

  const onCreateStorageKnowledgeRecordByUrl = (values: { query: string }) => {
    createStorageKnowledgeRecordByUrl({
      id: currentStorageId,
      body: {
        url: values.query
      }
    })
  }

  const handleDeleteItem = async (item: StorageKnowledgeRecord) => {
    await deleteStorageKnowledgeRecord({id: currentStorageId, vectorId: item.id})
    const filteredDataSource = addedKnowledgeRecords
      ?.filter((record) => record.id !== item.id)

    filteredDataSource && dispatch(setKnowledgeRecords(filteredDataSource))
  }

  const handleEditData = async (value: string, recordId: string) => {
    await createStorageKnowledgeRecord({
      id: currentStorageId,
      body: {
        singleRecord: true,
        metadata: { source: 'edit_data_ui' },
        data: value
      }
    })
    await deleteStorageKnowledgeRecord({id: currentStorageId, vectorId: recordId})
    dispatch(setEditedKnowledgeRecordId(recordId))
  }

  useEffect(() => {
    if (editedKnowledgeRecordId && storageKnowledgeRecords[0]) {
      const index = addedKnowledgeRecords
        ?.findIndex((record) => record.id === editedKnowledgeRecordId)

      if (typeof index === 'number' && index > -1 && addedKnowledgeRecords) {
        const updatedData = [...addedKnowledgeRecords]
        updatedData[index] = storageKnowledgeRecords[0]
        dispatch(setAddedKnowledgeRecords(updatedData))
      }
    }
  }, [editedKnowledgeRecordId, storageKnowledgeRecords])

  useEffect(() => {
    return () => {
      dispatch(setKnowledgeRecords([]))
      dispatch(setEditedKnowledgeRecords([]))
      dispatch(setEditedKnowledgeRecordId(''))
      dispatch(setAddedKnowledgeRecords([]))
      dispatch(setIsSuccess(false))
      form.resetFields()
    }
  }, [])



  return (
    <div className="add-by-url-tab">
      <h3>Paste individual URL here</h3>
      <Form form={form} onFinish={onCreateStorageKnowledgeRecordByUrl}>
        <Form.Item name="query" rules={[{required: true, validator: requiredValidator('Link is Required!')}]}>
          <Input size="large" />
        </Form.Item>
        <div className="search-buttons-container">
          <Form.Item>
            <AppPrimaryButton type="primary" htmlType="submit" className="app-primary-button-medium" size="middle">
              Index URL
            </AppPrimaryButton>
          </Form.Item>
        </div>
        <div className="custom-separator-list-container">
          <KnowledgeRecordsList
            records={addedKnowledgeRecords}
            loading={
              createStorageKnowledgeRecordMutationResult.isLoading ||
              deleteAiDataMutationResult.isLoading ||
              createStorageKnowledgeRecordByUrlMutationResult.isLoading}
            onPressDelete={handleDeleteItem}
            onPressEdit={handleEditData}
          />
        </div>
      </Form>
    </div>
  )
}

export default AddByUrlTab
