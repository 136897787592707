import React, { useEffect, useMemo, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { Button, message } from 'antd'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import ChatHeader from './ChatHeader'
import ChatComponent, { ConversationMessageItem } from '../../components/Chat/Chat'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import Icon from '../../components/UI/Icons/Icons'
import { GetChatRequest } from '../../types/public.type'
import { useLazyChatQuery, useLazyGetChatConfigQuery } from '../../store/api/pubic.api'
import { ConversationImageUrl, ConversationMessageRequestBody } from '../../types/conversation.type'
import { useUploadFileMutation } from '../../store/api/uploads.api'
import { useAppSelector } from '../../hooks/appHook'
import { Message } from '../../types/storage.type'
import './publicChat.less'

const copyUrlToClipboard = () => {
  const url = window.location.href
  navigator.clipboard
    .writeText(url)
    .then(() => {
      message.success('Page link has been copied to the clipboard!')
    })
    .catch((error) => {
      console.error('Failed to copy URL to clipboard:', error)
    })
}

const Chat = () => {
  const location = useLocation()
  const { accountId } = useParams()

  const listRef = useRef<HTMLDivElement>(null)

  /** Storage */
  const { chatSettings } = useAppSelector((state) => state.chat)

  /** Storage actions */
  const [postMessageHandler, { data, error, isFetching, isSuccess }] = useLazyChatQuery()
  const [uploadFile, uploadFileMutationResult] = useUploadFileMutation()
  const [getChatConfig] = useLazyGetChatConfigQuery()

  /** Response message handlers */
  useErrorMessage('Something went wrong', error)
  useErrorMessage('Something went wrong', uploadFileMutationResult.error)

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight
    }
  }

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search)
    const storageId = queryParams.get('storageId') || ''
    const agentId = queryParams.get('agentId') || ''

    return {
      storageId,
      agentId,
    }
  }

  useEffect(() => {
    const { agentId } = getQueryParams()
    if (accountId) {
      getChatConfig({ id: accountId, agentId })
    }
  }, [accountId])

  const uploadImage = async (image: File, chatRequestProps: GetChatRequest) => {
    const formData = new FormData()
    formData.append('user', JSON.stringify(chatRequestProps))
    formData.append('file', image)

    const result: { data: ConversationImageUrl } | { error: FetchBaseQueryError | SerializedError } = await uploadFile({
      body: formData,
    })

    if ('data' in result) {
      return result
    }
    return null
  }

  const send = async (values: ConversationMessageItem) => {
    const { agentId, storageId } = getQueryParams()
    const chatRequestProps = {} as GetChatRequest

    chatRequestProps.accountId = accountId

    if (storageId) {
      chatRequestProps.storageId = storageId
    }

    if (agentId) {
      chatRequestProps.agentId = agentId
    }

    if (data?.id) {
      chatRequestProps.conversationId = data.id
    }

    const message: ConversationMessageRequestBody = {
      role: 'user',
      content: [],
    }

    if (values.message) {
      message.content.push({
        type: 'text',
        text: values.message,
      })
    }

    if (values.linksImages?.length) {
      values.linksImages.forEach((url) => {
        message.content.push({
          type: 'image_url',
          image_url: {
            url,
            s3Uri: '',
          },
        })
      })
    }

    let uploadsImageResult: Array<{ data: ConversationImageUrl }> = []

    if (values.fileImages?.length) {
      try {
        const uploadPromises = values.fileImages.map((image: File) => uploadImage(image, chatRequestProps))

        const results = await Promise.all(uploadPromises)

        uploadsImageResult = results.filter(Boolean) as Array<{ data: ConversationImageUrl }>
      } catch (err) {
        console.log('Uploads images error', err)
      }
    }

    if (uploadsImageResult?.length) {
      uploadsImageResult.forEach(({ data }) => {
        message.content.push({
          type: 'image_url',
          image_url: data,
        })
      })
    }

    chatRequestProps.newMessage = message

    postMessageHandler(chatRequestProps)
  }

  useEffect(() => {
    scrollToBottom()
  }, [data])

  const initialData = useMemo(() => {
    let result = {
      id: '',
      messages: [] as Message[],
    }

    if (data) {
      result = cloneDeep(data)
    }

    if (chatSettings?.welcomeMessage) {
      result.messages.unshift({
        role: 'assistant',
        content: chatSettings?.welcomeMessage,
      })
    }

    return result
  }, [data, chatSettings])

  return (
    <div className="public-chat-page">
      <ChatHeader />
      <div className="public-chat-container">
        <ChatComponent
          ref={listRef}
          isEditable
          isPublic
          onSend={send}
          isFetching={isFetching}
          placeholder="Ask a question"
          successMessageSend={isSuccess}
          messages={initialData?.messages}
        />
        <Button className="copy-url-button" icon={<Icon name="copyUrl" />} type="link" onClick={copyUrlToClipboard}>
          <span className="button-text">copy page link</span>
        </Button>
      </div>
    </div>
  )
}

export default Chat
