import React, { LegacyRef, memo, useMemo } from 'react'
import remarkGfm from 'remark-gfm'
import rehypeHighlight from 'rehype-sanitize'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import CustomMessage from '../CustomMessage/CustomMessage'
import { Message } from '../../../../types/storage.type'

interface IProps {
  message: Message
  showRoles: boolean
  lastMessageRef?: LegacyRef<HTMLDivElement>
}

const ReactMarkdownContent = ({ message, showRoles, lastMessageRef }: IProps) => {
  const isUserMessage = useMemo(() => {
    return message.role === 'user' && Array.isArray(message.content)
  }, [message])

  const currentMessage = useMemo(() => {
    return typeof message.content === 'string' ? message.content : JSON.stringify(message.content)
  }, [message])

  return (
    <div id={message.role + '-paragraph-container'} ref={lastMessageRef}>
      {showRoles && <h4>{message.role}</h4>}
      {message.tool_calls?.length ? (
        <div className="paragraph function-call-paragraph">
          <h5>function_call {message.tool_calls[0].function.name}</h5>
          <p> ({message.tool_calls[0].function?.arguments})</p>
        </div>
      ) : (
        <div>
          <>
            {isUserMessage ? (
              <CustomMessage message={message}></CustomMessage>
            ) : (
              <>
                {message.content && (
                  <div id={'paragraph-' + message.role} className="paragraph">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeHighlight]}
                      components={{
                        a: ({ href, children }) => (
                          <a href={href} target="_blank" rel="noopener noreferrer">
                            {children}
                          </a>
                        ),
                        code: ({ children, inline }) => {
                          if (inline) {
                            return <code className="language-js">{children}</code>
                          }
                          return (
                            <pre>
                              <code className="language-js">{children}</code>
                            </pre>
                          )
                        },
                      }}
                    >
                      {currentMessage}
                    </ReactMarkdown>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      )}
    </div>
  )
}

export default memo(ReactMarkdownContent)
