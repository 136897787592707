import React from 'react'
import { Tabs, TabsProps } from 'antd'
import { useAppSelector } from '../../hooks/appHook'
import IntegrationTabContent from './components/IntegrationTabContent'
import BrandingTabContent from './components/BrandingTabContent'
import CallToActionTabContent from './components/CallToActionTabContent'
import SettingsTabContent from './components/SettingsTabContent'
import AIAgentPageHeader from './components/AIAgentPageHeader/AIAgentPageHeader'
import './aiAgent.less'

const items: TabsProps['items'] = [
  {
    key: 'settings',
    label: 'Settings',
    children: <SettingsTabContent />,
  },
  {
    key: 'cta',
    label: 'Call to Action',
    children: <CallToActionTabContent />,
  },
  {
    key: 'chat page settings',
    label: 'Chat Page Settings',
    children: <CallToActionTabContent />,
  },
  {
    key: 'widget',
    label: 'Widget',
    children: <BrandingTabContent />,
  },
  {
    key: 'integration',
    label: 'Integration',
    children: <IntegrationTabContent />,
  },
]

const AIAgent = () => {
  /** Storage */
  const { agents } = useAppSelector((state) => state.agents)

  return (
    <>
      <AIAgentPageHeader title="AI Agent" />
      <div className="container">
        {agents.length ? (
          <Tabs defaultActiveKey="settings" items={items} destroyInactiveTabPane />
        ) : (
          <div className="empty-storages-container">
            To continue working, it is necessary to have at least one created agent. To create a agent, please click the
            “Create new agent” button.
          </div>
        )}
      </div>
    </>
  )
}

export default AIAgent
