import React, {memo} from 'react'
import { Alert, Layout, Space } from 'antd'
import { Link } from 'react-router-dom'
import Logo from '../../../../components/UI/Logos/Logo'
import AccountMenu from '../../../../components/AccountMenu/AccountMenu'
import MobileNavigation from './components/MobileNavigation/MobileNavigation'
import { LocalStorage } from 'ts-localstorage'
import { ADMIN_USER_ID } from '../../../../config/consts'
import { IUser } from '../../../../types/profile.type'
const { Header: LayoutHeader } = Layout

interface IProps {
  user: IUser
}

const Header = ({user}: IProps) => {
  const adminUserId = LocalStorage.getItem(ADMIN_USER_ID)

  return <LayoutHeader>
    <div className="header">
      <div className="right-header-item">
        <Space size={25}>
          <div>
            <Link to="/" className="logo">
              <Logo name="cargoDark" width={'100%'} height={'100%'} />
            </Link>
            <AccountMenu />
          </div>
        </Space>
      </div>

      {!!adminUserId && <p id="admin-account-email">{user.email}</p>}

      {/** Mobile layout navigation */}
      <MobileNavigation userRole={user.role}/>

      {user?.status === 'unverified' && (
        <Alert
          message={
            <>
              Please verify your email address {user.email} to use Ioni. Resend verification email on your{' '}
              <Link to="/profile">profile page</Link>.
            </>
          }
          type="warning"
          style={{ marginTop: 20 }}
        />
      )}
    </div>
  </LayoutHeader>
}

export default memo(Header);
