import React, { useEffect, useMemo } from 'react'
import { useErrorMessage } from '../../../../../hooks/useErrorMessage'
import TestChatButton from '../../TestChatButton/TestChatButton'
import { useAppSelector } from '../../../../../hooks/appHook'
import {
  useCreateStorageKnowledgeRecordByStorageIdMutation,
  useDeleteStorageKnowledgeRecordByIdMutation,
  useLazyGetStorageKnowledgeRecordsByIdQuery,
} from '../../../../../store/api/storage.api'
import { StorageKnowledgeRecord } from '../../../../../types/storage.type'
import KnowledgeRecordsList from '../../KnowledgeRecordsList/KnowledgeRecordsList'
import { Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useGetDocumentQuery } from '../../../../../store/api/document.api'
import { useLocation, useNavigate } from 'react-router-dom'


type IProps = {
  setKnowledgeBasePage: (key: string) => void
}

const ReviewAllDataByDocumentTab = ({ setKnowledgeBasePage }: IProps) => {

  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const documentId = queryParams.get('documentId')
  /** Storage */
  const { currentStorageId } = useAppSelector((state) => state.storage)

  /** Api */
  const { data: document, isLoading: isGetDocumentLoading } = useGetDocumentQuery(documentId)

  /** Storage Actions */
  const [
    getStorageKnowledgeRecords,
    getStorageKnowledgeRecordsQueryResult,
  ] = useLazyGetStorageKnowledgeRecordsByIdQuery()

  const [
    createStorageKnowledgeRecord,
    createStorageKnowledgeRecordMutationResult,
  ] = useCreateStorageKnowledgeRecordByStorageIdMutation()

  const [
    deleteStorageKnowledgeRecord,
    deleteStorageKnowledgeRecordMutationResult,
  ] = useDeleteStorageKnowledgeRecordByIdMutation()

  useErrorMessage(
    'Server error!',
    getStorageKnowledgeRecordsQueryResult.error ||
    createStorageKnowledgeRecordMutationResult.error ||
    deleteStorageKnowledgeRecordMutationResult.error,
  )

  useEffect(() => {
    getStorageKnowledgeRecords({ id: currentStorageId, documentId: documentId || undefined })
  }, [currentStorageId, documentId])

  /** Handlers */

  const handleDeleteItem = async (item: StorageKnowledgeRecord) => {
    await deleteStorageKnowledgeRecord({ id: currentStorageId, vectorId: item.id })
    await getStorageKnowledgeRecords({ id: currentStorageId })
  }

  const handleEditData = async (value: string, recordId: string) => {
    await createStorageKnowledgeRecord({
      id: currentStorageId,
      body: {
        singleRecord: true,
        metadata: { source: 'edit_data_ui' },
        data: value,
      },
    })
    await deleteStorageKnowledgeRecord({ id: currentStorageId, vectorId: recordId })
    await getStorageKnowledgeRecords({ id: currentStorageId })
  }

  const onBack = () => {
    setKnowledgeBasePage('2')
    queryParams.delete('documentId')
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    })
  }


  const isLoading = useMemo(() => {
    return getStorageKnowledgeRecordsQueryResult.isFetching ||
      deleteStorageKnowledgeRecordMutationResult.isLoading ||
      createStorageKnowledgeRecordMutationResult.isLoading ||
      isGetDocumentLoading
  }, [getStorageKnowledgeRecordsQueryResult, deleteStorageKnowledgeRecordMutationResult, createStorageKnowledgeRecordMutationResult])

  return (
    <div className="custom-separator-list-container">
      <div className="tab-header">
        <div className="header-description">
          <div className="header-title-wrapper">
            <Button onClick={onBack}>
              <ArrowLeftOutlined /> Back
            </Button>
            <h2>{document?.name || document?.url || 'No data'}</h2>
          </div>
          <p>Review and edit all the data related to document.</p>
        </div>
        <TestChatButton />
      </div>
      <KnowledgeRecordsList
        records={getStorageKnowledgeRecordsQueryResult.data?.documents || []}
        loading={isLoading}
        onPressDelete={handleDeleteItem}
        onPressEdit={handleEditData}
      />
    </div>
  )
}

export default ReviewAllDataByDocumentTab
